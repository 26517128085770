import React, { Fragment, PropsWithChildren, ReactElement } from "react";
import { Menu, Transition } from "@headlessui/react";

import { MenuHelper } from "./menu.type";
import { MenuContext } from "./menu.hook";

export type XMenuUIState = MenuHelper;

export const XMenu = (props: PropsWithChildren) => {
  return (
    <Menu
      as={"div"}
      className={"xmodel xm-relative xm-inline-block xm-text-left"}
    >
      {({ open, close }) => (
        <>
          <MenuContext.Provider
            value={{
              is_open: open,
              close,
            }}
          >
            {props.children}
          </MenuContext.Provider>
        </>
      )}
    </Menu>
  );
};

export const XMenuButton = (
  props: PropsWithChildren<{ className?: string }>
) => {
  const { className } = props;
  return (
    <div>
      <Menu.Button className={className}>{props.children}</Menu.Button>
    </div>
  );
};

export const XMenuBody = (props: PropsWithChildren) => {
  return (
    <>
      <Transition
        as={Fragment}
        enter="xm-transition xm-ease-out xm-duration-100"
        enterFrom="xm-transform xm-opacity-0 xm-scale-95"
        enterTo="xm-transform xm-opacity-100 xm-scale-100"
        leave="xm-transition xm-ease-in xm-duration-75"
        leaveFrom="xm-transform xm-opacity-100 xm-scale-100"
        leaveTo="xm-transform xm-opacity-0 xm-scale-95"
      >
        <Menu.Items className="xm-absolute xm-min-w-[208px] xm-mt-2 xm-origin-top-right xm-divide-y xm-divide-gray-100 xm-rounded-md xm-bg-white xm-shadow-lg xm-ring-1 xm-ring-gray-800 xm-ring-opacity-30 focus:xm-outline-none">
          {props.children}
        </Menu.Items>
      </Transition>
    </>
  );
};

export const XMenuItem = (props: {
  children: (context: {
    active: boolean;
    disabled: boolean;
    close: () => void;
  }) => ReactElement;
}) => {
  return (
    <Menu.Item as={Fragment}>
      {({ active, disabled, close }) => (
        <div className="xm-px-4 xm-py-2">
          {props.children({ active, disabled, close })}
        </div>
      )}
    </Menu.Item>
  );
};
