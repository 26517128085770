import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { Transition } from "@headlessui/react";

import { NodeModel } from "./tree.model";
import { toNodeProps } from "./tree.helper";
import { ChildrenNodeProps } from "./tree.type";

const Open = React.memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    className="xm-w-5 xm-h-5"
  >
    <path
      fillRule="evenodd"
      d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
      clipRule="evenodd"
    />
  </svg>
));

const Close = React.memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    fill="currentColor"
    className="xm-w-5 xm-h-5"
  >
    <path
      fillRule="evenodd"
      d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
      clipRule="evenodd"
    />
  </svg>
));

export const XNode = (props: {
  node: NodeModel;
  expand?: boolean;
  children?: (props: ChildrenNodeProps) => ReactNode;
}) => {
  const { node, children, expand = false } = props;

  const [isShowing, setIsShowing] = useState(expand);

  const nodePropsFactory = useCallback(
    (node: NodeModel) => {
      return toNodeProps(node);
    },
    [node]
  );

  const nodeProps = useMemo(() => {
    return nodePropsFactory(node);
  }, [node, nodePropsFactory]);

  return (
    <li>
      <div className="xm-flex">
        <button onClick={() => setIsShowing((isShowing) => !isShowing)}>
          {nodeProps.is_root ? isShowing ? <Open /> : <Close /> : <Close />}
        </button>
        {children ? children(nodeProps) : node.title}
      </div>
      <Transition show={isShowing}>
        {nodeProps.is_root ? (
          <ul className="xm-ml-5">
            {node.children.map((item, index) => (
              <XNode
                node={item}
                key={index}
                children={children}
                expand={expand}
              />
            ))}
          </ul>
        ) : null}
      </Transition>
    </li>
  );
};
