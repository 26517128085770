import React, { ReactNode } from "react";
import { TreeModel } from "./tree.model";
import { XNode } from "./node.component";
import { ChildrenNodeProps } from "./tree.type";

export const XTree = (props: {
  tree: TreeModel;
  expand?: boolean;
  include_root?: boolean;
  children?: (props: ChildrenNodeProps) => ReactNode;
}) => {
  const { tree, children, expand = false, include_root = false } = props;
  return (
    <ul className="xmodel">
      {include_root ? (
        <>
          <XNode node={tree} expand={expand}>
            {children ? (node_props) => children(node_props) : null}
          </XNode>
        </>
      ) : (
        <>
          {tree.children.map((item, index) => (
            <XNode node={item} key={index} expand={expand}>
              {children ? (node_props) => children(node_props) : null}
            </XNode>
          ))}
        </>
      )}
    </ul>
  );
};
