import React, { useContext } from "react";
import { MenuHelper } from "./menu.type";

export const MenuContext = React.createContext<MenuHelper>({
  close: () => {},
  is_open: false,
});

export const useMenuContext = () => {
  const context = useContext(MenuContext);
  return context;
};
