import React, {
  Fragment,
  useRef,
  PropsWithChildren,
  CSSProperties,
  useMemo,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CloseIcon } from "../close_icon";

const ModelTitle = React.memo(
  (props: { title?: string }) => {
    const { title } = props;
    return (
      <>
        {title ? (
          <Dialog.Title as="h3" className="xm-xdialog_title xm-pb-1">
            {title}
          </Dialog.Title>
        ) : null}
      </>
    );
  },
  (prev, curr) => prev.title !== curr.title
);

export const XModel = (
  props: PropsWithChildren<{
    show?: boolean;
    onCloseModel: () => void;
    title?: string;
    initialWidth?: string;
    initialHeight?: string;
  }>
) => {
  const {
    show = false,
    onCloseModel,
    title,
    initialWidth = "1024px",
    initialHeight,
  } = props;
  const cancelButtonRef = useRef();

  const handleClose = () => {
    if (onCloseModel) {
      onCloseModel();
    }
  };

  const calStyle = useMemo((): CSSProperties => {
    const style: CSSProperties = {
      paddingTop: "1.25rem",
      paddingLeft: "1.5rem",
      paddingRight: "1.5rem",
      paddingBottom: "1.25rem",
    };
    if (initialWidth) {
      style.width = initialWidth;
    }
    if (initialHeight) {
      style.height = initialHeight;
    }
    return style;
  }, [initialWidth, initialHeight]);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        static
        className="xmodel xm-xdialog"
        initialFocus={cancelButtonRef}
        open={show}
        onClose={handleClose}
      >
        <div className="xm-xdialog_body">
          <Transition.Child
            as={Fragment}
            enter="xm-xm-ease-out xm-xm-duration-300"
            enterFrom="xm-opacity-0"
            enterTo="xm-opacity-100"
            leave="xm-ease-in xm-duration-200"
            leaveFrom="xm-opacity-100"
            leaveTo="xm-opacity-0"
          >
            <Dialog.Overlay className="xm-xdialog_overlay" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="xm-hidden sm:xm-inline-block xm-align-middle sm:xm-h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="xm-ease-out xm-duration-300"
            enterFrom="xm-opacity-0 xm-translate-y-4 sm:xm-translate-y-0 sm:xm-scale-95"
            enterTo="xm-opacity-100 xm-translate-y-0 sm:xm-scale-100"
            leave="xm-ease-in xm-duration-200"
            leaveFrom="xm-opacity-100 xm-translate-y-0 sm:xm-scale-100"
            leaveTo="xm-opacity-0 xm-translate-y-4 sm:txm-ranslate-y-0 sm:xm-scale-95"
          >
            <div
              className="xm-xdialog_popup xm-text-left xm-shadow xm-transform xm-transition-all xm-min-w-full md:xm-min-w-1/4"
              style={calStyle}
            >
              <ModelTitle title={title} />
              <div>
                <button
                  ref={cancelButtonRef}
                  className="xm-outline-none focus:xm-outline-none xm-bg-transparent"
                  style={{
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                    paddingTop: "0.125rem",
                    paddingRight: "0.125rem",
                  }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </button>
                <div>{props.children}</div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
